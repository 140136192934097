import Typography from '@mui/material/Typography';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import circle from '../../sources/our_services/circle.svg'
import service1 from '../../sources/our_services/service1_logo_design.svg'
import service2 from '../../sources/our_services/service2_graphics.svg'
import service3 from '../../sources/our_services/service3_animations.svg'
import service4 from '../../sources/our_services/service4_UI_UX.svg'
import service5 from '../../sources/our_services/service5_photo_video_editing.svg'
import service6 from '../../sources/our_services/service6_AI.svg'
import service7 from '../../sources/our_services/service7_website.svg'
import service8 from '../../sources/our_services/service8_mobile_app.svg'
import service9 from '../../sources/our_services/service9_software_system.svg'
import service10 from '../../sources/our_services/service10_VR_AR.svg'
import service11 from '../../sources/our_services/service11_blockchain.svg'
import service12 from '../../sources/our_services/service12_metaverse.svg'

export default function OurServices() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const services = [
        { icon: service1, text: 'service1' },
        { icon: service2, text: 'service2' },
        { icon: service3, text: 'service3' },
        { icon: service4, text: 'service4' },
        { icon: service5, text: 'service5' },
        { icon: service6, text: 'service6' },
        { icon: service7, text: 'service7' },
        { icon: service8, text: 'service8' },
        { icon: service9, text: 'service9' },
        { icon: service10, text: 'service10' },
        { icon: service11, text: 'service11' },
        { icon: service12, text: 'service12' },
    ];

    const iconSize = isMobile ? '70px' : '102px';
    const gridSize = isMobile ? 6 : isTablet ? 6 : 4;

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                px: { xs: 2, sm: 4, md: 6 },
                py: { xs: 4, sm: 6, md: 8 },
            }}
        >
            <Typography 
                sx={{
                    color: '#ffffff',
                    textAlign: 'center',
                    fontFamily: 'Inria Sans',
                    fontSize: { xs: '24px', sm: '28px', md: '32px' },
                    fontWeight: 700,
                    mb: { xs: 4, sm: 6, md: 10 }
                }}
            >
                {t('OurServices.title')}
            </Typography>

            <Grid 
                container 
                spacing={{ xs: 4, sm: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
            >
                {services.map((service, index) => (
                    <Grid 
                        item 
                        xs={gridSize}
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box 
                            sx={{
                                width: iconSize,
                                height: iconSize,
                                backgroundImage: `url(${circle})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mb: { xs: 2, sm: 3 },
                                flexShrink: 0, // Prevents icon from shrinking
                            }}
                        >
                            <img
                                src={service.icon}
                                alt={`service${index + 1}`}
                                style={{
                                    width: '65%',
                                    height: '65%',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                        <Typography 
                            sx={{
                                color: '#ffffff',
                                textAlign: 'center',
                                fontFamily: 'Inria Sans',
                                fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                fontWeight: 400,
                                textTransform: 'uppercase',
                                minHeight: { xs: '48px', sm: '54px', md: '60px' }, // Ensures consistent height for text area
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {t(`OurServices.${service.text}`)}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}