import Typography from '@mui/material/Typography';
import { Box, Grid, Link, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import cityu from '../../sources/supporting_parties/cityu.png'
import polyu from '../../sources/supporting_parties/polyu.png'
import itc from '../../sources/supporting_parties/itc.png'
import hkstp from '../../sources/supporting_parties/hkstp.png'

import takWing from '../../sources/partners/takWing.png'
import firstSeries from '../../sources/partners/firstSeries.png'
import o2Wellness from '../../sources/partners/02Wellness.png'
import art from '../../sources/partners/art.png'
import comrade from '../../sources/partners/comrade.png'
import greenTake from '../../sources/partners/greenTake.png'
import greenLab from '../../sources/partners/greenLab.png'
import mobioz from '../../sources/partners/mobioz.png'
import ninja from '../../sources/partners/ninja.png'

const supportingParties = [
  { id: 1, image: cityu, alt: 'City University of Hong Kong (CityU)', link: 'https://www.cityu.edu.hk/' },
  { id: 2, image: polyu, alt: 'The Hong Kong Polytechnic University (PolyU)', link: 'https://www.polyu.edu.hk/' },
  { id: 3, image: itc, alt: 'The Innovation and Technology Commission (ITC)', link: 'https://www.itc.gov.hk/en/index.html' },
  { id: 4, image: hkstp, alt: 'The Hong Kong Science and Technology Parks Corporation (HKSTP)', link: 'https://www.hkstp.org/' },
];

const partners = [
  { id: 1, image: takWing, alt: 'Tak Wing' },
  { id: 2, image: firstSeries, alt: 'First Series' },
  { id: 3, image: o2Wellness, alt: '02Wellness' },
  { id: 4, image: art, alt: 'Art' },
  { id: 5, image: comrade, alt: 'Comrade' },
  { id: 6, image: greenTake, alt: 'Green Take' },
  { id: 7, image: greenLab, alt: 'Green Lab' },
  { id: 8, image: mobioz, alt: 'Mobioz' },
  { id: 9, image: ninja, alt: 'Ninja' },
];

const styles = {
  title: {
    color: '#5CAEDD',
    textAlign: 'center',
    fontFamily: 'Inria Sans',
    fontWeight: 700,
  },
  imageContainer: {
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '100px',
    objectFit: 'contain',
  },
};

const LogoGrid = ({ items, xs, sm, md }) => (
  <Grid container spacing={3} justifyContent="center" alignItems="center">
    {items.map((item) => (
      <Grid item xs={xs} sm={sm} md={md} key={item.id}>
        <Box
          component={Link}
          href={item.link}
          target={item.link ? '_blank' : undefined}
          sx={styles.imageContainer}
        >
          <Box
            component="img"
            src={item.image}
            alt={item.alt}
            sx={styles.image}
          />
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default function Partners() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box padding={isMobile ? 2 : 4}>
      <Box marginBottom={6}>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          gutterBottom
          sx={styles.title}
        >
          {t('SupportingParties.title')}
        </Typography>
        <LogoGrid
          items={supportingParties}
          xs={6}
          sm={6}
          md={3}
        />
      </Box>

      <Box>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          gutterBottom
          sx={styles.title}
        >
          {t('Partners.title')}
        </Typography>
        <LogoGrid
          items={partners}
          xs={4}
          sm={4}
          md={4}
        />
      </Box>
    </Box>
  );
}