import Typography from '@mui/material/Typography';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import moveForward from '../../sources/introduction/moveForward.png';
import Intro_image from '../../sources/introduction/Intro_image.png';

export default function Introduction() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            pt: { xs: 2, md: 4 }
        }}>
            {/* Forward Image */}
            <Box sx={{
                width: '100%',
                mb: { xs: 10, md: 14 },
                display: 'flex',
                justifyContent: 'center'
            }}>
                <img
                    src={moveForward}
                    alt="Move Forward"
                    style={{
                        width: 'auto',
                        height: isMobile ? '150px' : '380px',
                        objectFit: 'contain'
                    }}
                />
            </Box>

            {/* Content Card */}
            <Box sx={{
                width: { xs: '90%', md: '60%' },
                mx: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(5px)', // This will make the box blurred
                borderRadius: { xs: '40px 0px 40px 0px', md: '100px 0px 100px 0px'},
                p: { xs: 3, md: 4 },
                textAlign: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}>
                {/* Icon */}
                <Box sx={{ mb: 2 }}>
                    <img
                        src={Intro_image}
                        alt="icon"
                        style={{
                            width: isMobile ? '40px' : '60px',
                            height: 'auto'
                        }}
                    />
                </Box>

                {/* Title */}
                <Box sx={{ mb: 2 }}>
                    <Typography
                        component="span" 
                        sx={{
                            fontFamily: 'Charm',
                            fontSize: { xs: '48px', md: '60px' },
                            lineHeight: 1,
                            mb: 1
                        }}
                    >
                        K
                    </Typography>
                    <Typography
                        component="span" 
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: { xs: '22px', md: '24px' },
                            fontWeight: 500
                        }}
                    >
                        ADIMA, {t('Introduction.title1')}<em>{t('Introduction.title2')}</em>{t('Introduction.title3')}
                    </Typography>
                </Box>

                {/* Description */}
                <Box sx={{ maxWidth: '90%', mx: 'auto' }}>
                    <Typography
                        sx={{
                            fontFamily: 'InriaSans-Regular',
                            fontSize: { xs: '18px', md: '18px' },
                            mb: 2
                        }}
                    >
                        {t('Introduction.text1')}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'InriaSans-Regular',
                            fontSize: { xs: '18px', md: '18px' }
                        }}
                    >
                        {t('Introduction.text2')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}