
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ContactUs() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:info@kadimatech.hk?subject=Contact Us - ${subject} - from ${name}&body=${message} - From: ${email}`;
  };

  const fieldsAreFilled = () => {
    return name !== "" && email !== "" && subject !== "" &&message !== "";
  };
  
  return (
    <Grid item container xs={12} display={'flex'} justifyContent={'center'}>
      <Box 
        id="contactUs" 
        sx={{ 
          width: isMobile ? '90%' : '70%',
          maxWidth: 900,
          border: 0,
          borderRadius: '40px',
          backgroundImage: 'linear-gradient(180deg, #FEF5EC 0%, #EBEEFF 100%)', 
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          my: isMobile ? 2 : 4
        }}
      >
        <Grid container>
          {/* Title and Button Section */}
          <Grid 
            item 
            xs={12} 
            md={6} 
            sx={{
              p: isMobile ? 2 : 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              gap: isMobile ? 3 : 0
            }}
          >
            <Typography 
              sx={{ 
                fontFamily: 'NotoSerif-Bold',
                fontSize: isMobile ? '32px' : '42px',
                color: '#7DC4A2',
                mb: isMobile ? 2 : 0
              }}
            >
              {t('ContactUs.title')}
            </Typography>

            {isMobile && (
              <Box sx={{ width: '100%' }}>
                <TextField 
                  required 
                  id="name" 
                  label={t('ContactUs.name')} 
                  fullWidth 
                  sx={{ 
                    backgroundColor: 'white', 
                    borderRadius: '5px',
                    mb: 2
                  }} 
                  value={name} 
                  onChange={e => setName(e.target.value)} 
                />
                <TextField 
                  required 
                  id="email" 
                  label={t('ContactUs.email')} 
                  fullWidth 
                  sx={{ 
                    backgroundColor: 'white', 
                    borderRadius: '5px',
                    mb: 2
                  }} 
                  value={email} 
                  onChange={e => setEmail(e.target.value)} 
                />
                <TextField 
                  required 
                  id="subject" 
                  label={t('ContactUs.subject')} 
                  fullWidth 
                  sx={{ 
                    backgroundColor: 'white', 
                    borderRadius: '5px',
                    mb: 2
                  }} 
                  value={subject} 
                  onChange={e => setSubject(e.target.value)} 
                />
                <TextField 
                  required 
                  id="message" 
                  label={t('ContactUs.message')} 
                  fullWidth 
                  multiline 
                  rows={5} 
                  sx={{ 
                    backgroundColor: 'white', 
                    borderRadius: '5px',
                    mb: 2
                  }} 
                  value={message} 
                  onChange={e => setMessage(e.target.value)} 
                />
              </Box>
            )}

            <Button
              variant="contained"
              sx={{
                fontFamily: "HelveticaNeue",
                fontSize: isMobile ? "20px" : "24px",
                width: '150px',
                border: 0,
                borderRadius: '20px',
                borderColor: '#D19676',
                backgroundColor: '#D19676',
                color: "white",
                mt: isMobile ? 0 : 'auto',
                '&:hover': {
                  border: 1,
                  borderColor: '#f7eae4',
                  backgroundColor: '#cd8c69',
                },
                '&:disabled': {
                  borderColor: '#e4c1af',
                  backgroundColor: '#e4c1af',
                },
              }}
              onClick={handleSubmit}
              disabled={!fieldsAreFilled()}
            >
              {t('ContactUs.send')}
            </Button>
          </Grid>

          {/* Form Fields Section - Desktop Only */}
          {!isMobile && (
            <Grid 
              item 
              md={6} 
              sx={{
                p: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Box p={1} sx={{ width: '100%' }}>
                <TextField required id="name" label={t('ContactUs.name')} fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={name} onChange={e => setName(e.target.value)} />
              </Box>
              <Box p={1} sx={{ width: '100%' }}>
                <TextField required id="email" label={t('ContactUs.email')} fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={email} onChange={e => setEmail(e.target.value)} />
              </Box>
              <Box p={1} sx={{ width: '100%' }}>
                <TextField required id="subject" label={t('ContactUs.subject')} fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={subject} onChange={e => setSubject(e.target.value)} />
              </Box>
              <Box p={1} sx={{ width: '100%' }}>
                <TextField required id="message" label={t('ContactUs.message')} fullWidth multiline rows={5} sx={{ backgroundColor: 'white', borderRadius: '5px' }} value={message} onChange={e => setMessage(e.target.value)} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
