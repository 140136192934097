import { Button, Divider, Typography, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FacebookLogo from '../../sources/Icon/FacebookIcon.png';
import InstagramLogo from '../../sources/Icon/InstagramIcon.png';
import TwitterLogo from '../../sources/Icon/TwitterIcon.png';
import KadimaLogo from '../../sources/kadima_logo_name.png';

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ px: isMobile ? 2 : 4, py: 4 }}>
      {isMobile ? (
        <Grid container direction="column" alignItems="center">
          {/* Logo Section */}
          <Grid item container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={KadimaLogo}
                alt="Kadima"
                sx={{
                  width: isMobile ? '60%' : '30%',
                  maxWidth: '300px',
                  height: 'auto'
                }}
              />
            </Grid>
          </Grid>

          {/* Follow Us Section */}
          <Grid item container direction="column" alignItems="center" sx={{ mb: 4 }}>
            <Typography 
              fontFamily="Lava Devanagari"
              color="#5CAEDD"
              fontSize={isMobile ? 18 : 20}
              fontWeight={500}
              sx={{ mb: 2 }}
            >
              {t('Footer.text')}
            </Typography>

            <Grid container justifyContent="center" spacing={2} sx={{ maxWidth: isMobile ? '250px' : '300px' }}>
              {[
                { icon: FacebookLogo, link: 'https://www.facebook.com/' },
                { icon: InstagramLogo, link: 'https://www.instagram.com' },
                { icon: TwitterLogo, link: 'https://twitter.com' }
              ].map((social, index) => (
                <Grid item xs={4} key={index} sx={{ textAlign: 'center' }}>
                  <Link href={social.link}>
                    <Box
                      component="img"
                      src={social.icon}
                      alt={social.link.split('.com')[0].split('www.')[1]}
                      sx={{
                        height: isMobile ? '40px' : '50px',
                        width: 'auto',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'scale(1.1)'
                        }
                      }}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Footer Text Section */}
          <Grid item container 
            direction={isMobile ? 'column' : 'row'} 
            justifyContent="space-between"
            alignItems="center"
            spacing={isMobile ? 2 : 0}
            sx={{ mt: 2 }}
          >
            <Grid item xs={12} md={6} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
              <Typography 
                fontFamily="HelveticaNeue" 
                fontSize="14px"
                sx={{ opacity: 0.8 }}
              >
                © 2024 Kadima Technology Limited All rights reserved.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={{ textAlign: isMobile ? 'center' : 'right' }}>
              <Box 
                display="flex" 
                justifyContent={isMobile ? 'center' : 'flex-end'}
                gap={2}
              >
                <MuiLink 
                  onClick={() => navigate(`/`)} 
                  sx={{ 
                    textDecoration: "none", 
                    color: "black",
                    cursor: 'pointer',
                    fontSize: "14px",
                    fontFamily: "HelveticaNeue",
                    '&:hover': {
                      color: '#5CAEDD'
                    }
                  }}
                >
                  {t('Footer.TOU')}
                </MuiLink>
                <Divider orientation="vertical" flexItem />
                <MuiLink 
                  onClick={() => navigate(`/PrivacyPolicy`)} 
                  sx={{ 
                    textDecoration: "none", 
                    color: "black",
                    cursor: 'pointer',
                    fontSize: "14px",
                    fontFamily: "HelveticaNeue",
                    '&:hover': {
                      color: '#5CAEDD'
                    }
                  }}
                >
                  {t('Footer.PP')}
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column"
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            
          }}>
          <Grid item container xs={12} direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
            

            <Grid py={3}>
                <img
                  src={KadimaLogo}
                  alt="kadimaLogo"
                  width="30%"
                  object-fit="contain"
                />
            </Grid>

            <Grid py={3}>
              <Typography fontFamily={"Inria Sans"} color={"#5CAEDD"} fontSize={24} fontWeight={700}>
              {t('Footer.text')}
              </Typography>
            </Grid>

            <Grid item py={3} container spacing={2} direction={'row'} sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
              <Grid item xs={4} md={1}>
                <Link href='https://www.facebook.com/'>
                  <img
                    src={FacebookLogo}
                    alt="Facebook"
                    width="auto"
                    height="50px"
                  />
                </Link>
              </Grid>
              <Grid item xs={4} md={1}>
                <Link href='https://www.instagram.com'>
                  <img
                    src={InstagramLogo}
                    alt="Instagram"
                    width="auto"
                    height="50px"
                  />
                </Link>
              </Grid>
              <Grid item xs={4} md={1}>
                <Link href='https://twitter.com'>
                  <img
                    src={TwitterLogo}
                    alt="Twitter"
                    width="auto"
                    height="50px"
                  />
                </Link>
              </Grid>
            </Grid>

          </Grid>
          
          <Grid item container direction="row"
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
            }}>
            <Grid item xs={12} md={4} mb={2} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
              <Typography fontFamily={"HelveticaNeue"} fontSize={"14px"} textAlign={'left'}>
                  © 2024 Kadima Technology Limited All rights reserved.
              </Typography>
            </Grid>

            <Grid container item xs={0} md={1}></Grid>
            <Grid container item xs={0} md={1}></Grid>
            <Grid container item xs={0} md={1}></Grid>
            <Grid container item xs={0} md={1}></Grid>
            
            <Grid item xs={12} md={4} mb={2} display="flex" justifyContent={{ xs: "center", md: "flex-end" }}>
              <Box display={'flex'} flexDirection={'row'} justifyContent={{ xs: "center", md: "space-between" }}>
                <MuiLink onClick={() => navigate(`/`)} mr={2} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
                  <Typography fontFamily={"HelveticaNeue"} fontSize={"14px"}>
                    {t('Footer.TOU')}
                  </Typography>
                </MuiLink>
                <Divider orientation="vertical" flexItem />
                <MuiLink onClick={() => navigate(`/PrivacyPolicy`)} ml={2} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
                  <Typography fontFamily={"HelveticaNeue"} fontSize={"14px"}>
                      {t('Footer.PP')}
                  </Typography>
                </MuiLink>
                  
              </Box>
              </Grid>
          </Grid>
          
        </Grid >
      )}
    </Box>
  );
}