import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import ChangeLanguage from './ChangeLanguage.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import KadimaLogo from '../../sources/kadima_logo.png';

function AppBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const appBarBackgroundColor = "rgba(255, 255, 255, 0.80)";

  const handleNavigation = (sectionId) => {
    if (location.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(`/#${sectionId}`);
    }
    setDrawerOpen(false);
  };

  const navigationLinks = [
    { label: t('TopBar.ourServices'), id: 'ourServices' },
    { label: t('TopBar.ourProducts'), id: 'ourProducts' },
    { label: t('TopBar.contactUs'), id: 'contactUs' },
  ];

  const MobileDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        '& .MuiDrawer-paper': {
          zIndex: 1100
        }
      }}
    >
      <Box sx={{ width: 250, pt: 10 }}>
        <List>
          {navigationLinks.map((link) => (
            <ListItem 
              button 
              key={link.id}
              onClick={() => handleNavigation(link.id)}
              sx={{ color: '#59A9D6' }}
            >
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
          <ListItem sx={{ justifyContent: 'center', mt: 2 }}>
            <ChangeLanguage />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ 
      height: 'auto', 
      width: '100%', 
      backgroundColor: appBarBackgroundColor,
      position: 'sticky',
      top: 0,
      zIndex: 100,
    }}>
      <Grid 
        container 
        sx={{
          padding: { xs: 1, md: '20px 40px 6px 40px' },
          alignItems: "center",
        }}
      >
        <Grid item xs={isMobile ? 8 : 3} container spacing={2} sx={{ 
          justifyContent: "flex-start", 
          alignItems: "center",
          mt: { xs: 1, md: 0 },
          pl: { xs: 2, md: 0 }
        }}>
          <MuiLink onClick={() => handleNavigation('introduction')} sx={{ cursor: 'pointer' }}>
            <img 
              src={KadimaLogo} 
              alt="Kadima Logo" 
              style={{
                height: isMobile ? '30px' : '50px',
                width: 'auto',
              }}
            />
          </MuiLink>
        </Grid>

        {isMobile ? (
          <Grid item xs={4} container justifyContent="flex-end">
            <IconButton 
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ color: '#59A9D6' }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        ) : (
          <Grid item xs={9} container gap={2} spacing={2} sx={{ 
            justifyContent: "flex-end", 
            alignItems: "center" 
          }}>
            {navigationLinks.map((link) => (
              <MuiLink 
                key={link.id}
                onClick={() => handleNavigation(link.id)} 
                sx={{ 
                  textDecoration: "none", 
                  color: "black", 
                  cursor: 'pointer' 
                }}
              >
                <Typography 
                  sx={{ '&:hover': { textDecoration: 'underline' } }} 
                  color="#59A9D6"
                >
                  {link.label}
                </Typography>
              </MuiLink>
            ))}
            <Box>
              <ChangeLanguage />
            </Box>
          </Grid>
        )}
      </Grid>
      <MobileDrawer />
    </Box>
  );
}

export default AppBar;