import React, { useRef, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container'

import Introduction from '../sections/Introduction.js';
import OurServices from '../sections/OurServices.js';
import WorkProcess from '../sections/WorkProcess.js';
import OurProducts from '../sections/OurProducts.js';
import Partners from '../sections/Partners.js';
import ContactUs from '../sections/ContactUs';
import Footer from '../sections/Footer.js';

import Heading_BG from '../../sources/Heading_BG.png'
import mainBackground from '../../sources/mainBackground.png'
import web from '../../sources/web.png'


function Home() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const location = useLocation();

  const ourServicesRef = useRef(null);
  const ourProductsRef = useRef(null);
  const contactRef = useRef(null);
  

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  

  

  return (
    <Box
      sx={{
        height: 'auto',
        width: '100%',
        backgroundImage: `url(${mainBackground})`,
        backgroundPosition: "center top",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#FADDC6",
        display:"flex",
        flexDirection:"column",
        pt:"xs: 15, md: 20 ",
        margin: '0',
        overflowX: 'hidden'
      }}
    >
    
    
      <Box sx={{ backgroundImage: `url(${Heading_BG})`, backgroundSize: "100% 30%",backgroundRepeat: "no-repeat", }}>
        <Box sx={{ backgroundImage: `url(${web})`, backgroundSize: {xs: '150% 100%', md: '100% 100%'}, backgroundRepeat: "no-repeat",}}>
            <Box id="introduction" paddingTop={10} paddingBottom={0} sx={{ width: '100%'}}>
                <Container maxWidth="lg">
                    <Introduction />
                </Container>
            </Box>
        </Box>
        

        <Box ref={ourServicesRef} id="ourServices" py={7} sx={{ width: '100%', background: 'rgba(0, 0, 0, 0.50)', }}>
        
          <Container maxWidth="lg">           
            <OurServices />
          </Container>
        </Box>

        <Box id="workProcess" py={7} pb={15} sx={{ width: '100%', background: 'rgba(0, 0, 0, 0.50)', }}>
          <Container maxWidth='lg'>
            <WorkProcess />
          </Container>         
        </Box>

        <Box ref={ourProductsRef} id="ourProducts" py={14} pb={7} sx={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.60)' }}>
          <Container maxWidth="lg">
            <OurProducts />
          </Container>          
        </Box>
          
        <Box id="partners" py={7} pb={7} sx={{ width: '100%',  }}>
          <Container maxWidth="lg">
            <Partners />
          </Container>         
        </Box>

        <Box ref={contactRef} id="contactUs" py={7} pb={7} sx={{ width: '100%', }}>
          <Container maxWidth="lg">
            <ContactUs />
          </Container>
        </Box>

        <Box id="footer" py={7} pb={7} sx={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.50)'}}>
          <Container>
            <Footer />
          </Container>         
        </Box>

        
        
      </Box>
    
    </Box>
  );
}

export default Home;