import Typography from '@mui/material/Typography';
import { Box, Grid, Link, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import product1 from '../../sources/our_products/product1_Enspiren.png'
import product2 from '../../sources/our_products/product2_Levav.png'
import product3 from '../../sources/our_products/product3.png'
import product4 from '../../sources/our_products/product4.png'
import product5 from '../../sources/our_products/product5.png'
import product6 from '../../sources/our_products/product6.png'

const products = [
    { id: 1, image: product1, alt: 'product1', link: 'https://enspiren.hk/' },
    { id: 2, image: product2, alt: 'product2', link: 'https://levav.com.hk/' },
    { id: 3, image: product3, alt: 'product3', link: '' },
    { id: 4, image: product4, alt: 'product4', link: '' },
    { id: 5, image: product5, alt: 'product5', link: '' },
    { id: 6, image: product6, alt: 'product6', link: '' },
  ];
  
  const styles = {
    title: {
      color: '#5CAEDD',
      textAlign: 'center',
      fontFamily: 'Inria Sans',
      fontWeight: 700,
    },
    learnMoreButton: {
      borderRadius: '350px',
      background: '#60BAEC',
      '&:hover': {
        background: '#4fa8d9',
      },
      transition: 'background 0.3s',
    },
    learnMoreText: {
      color: '#ffffff',
      textAlign: 'center',
      fontFamily: 'Inria Sans',
      fontWeight: 400,
    },
    productImage: {
      width: '100%',
      height: 'auto',
      transition: 'transform 0.3s',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  };


export default function OurProducts() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
      padding={isMobile ? 2 : 4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        paddingBottom={3}
        sx={styles.title}
      >
        {t('OurProducts.title')}
      </Typography>

      <Grid
        container
        spacing={isMobile ? 2 : 3}
        justifyContent="center"
        alignItems="stretch"
      >
        {products.map((product) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={product.id}
            sx={{ display: 'flex' }}
          >
            <Box
              component={Link}
              href={product.link || undefined}
              target={product.link ? '_blank' : undefined}
              sx={{
                width: '100%',
                textDecoration: 'none',
                display: 'block',
              }}
            >
              <Box
                component="img"
                src={product.image}
                alt={product.alt}
                sx={styles.productImage}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box
        component={Link}
        href="#"
        underline="none"
        sx={styles.learnMoreButton}
        marginTop={4}
        padding="10px 30px"
      >
        <Typography
          variant={isMobile ? 'body2' : 'body1'}
          sx={styles.learnMoreText}
        >
          {t('OurProducts.learn_more')}
        </Typography>
      </Box>
    </Box>
        
    )
}