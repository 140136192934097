import Typography from '@mui/material/Typography';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import circle from '../../sources/work_process/circle.svg'
import line from '../../sources/work_process/line_connect.png'
import process1 from '../../sources/work_process/process1_research.svg'
import process2 from '../../sources/work_process/process2_plan.svg'
import process3 from '../../sources/work_process/process3_design.svg'
import process4 from '../../sources/work_process/process4_development.svg'
import process5 from '../../sources/work_process/process5_testing.svg'
import process6 from '../../sources/work_process/process6_delivery.svg'

export default function WorkProcess() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const process = [
        { icon: process1, text: 'process1'},
        { icon: process2, text: 'process2'},
        { icon: process3, text: 'process3'},
        { icon: process4, text: 'process4'},
        { icon: process5, text: 'process5'},
        { icon: process6, text: 'process6'},
    ];

    const iconSize = isMobile ? '70px' : '102px';
    const gridSize = isMobile ? 4 : isTablet ? 4 : 2;

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                px: { xs: 2, sm: 4, md: 6 },
                py: { xs: 4, sm: 6, md: 8 },
            }}
        >
            <Typography 
                sx={{
                    color: '#ffffff',
                    textAlign: 'center',
                    fontFamily: 'Inria Sans',
                    fontSize: { xs: '24px', sm: '28px', md: '32px' },
                    fontWeight: 700,
                    mb: { xs: 4, sm: 6, md: 10 }
                }}
            >
                {t('WorkProcess.title')}
            </Typography>

            <Grid 
                container 
                spacing={{ xs: 4, sm: 6, md: 8 }}
                justifyContent="center"
                alignItems="stretch"
            >
                {process.map((process, index) => (
                    <Grid 
                        item 
                        xs={gridSize}
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box 
                            sx={{
                                width: iconSize,
                                height: iconSize,
                                backgroundImage: `url(${circle})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mb: { xs: 2, sm: 3 },
                                flexShrink: 0, // Prevents icon from shrinking
                            }}
                        >
                            <img
                                src={process.icon}
                                alt={`process${index + 1}`}
                                style={{
                                    width: '65%',
                                    height: '65%',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                        <Typography 
                            sx={{
                                color: '#ffffff',
                                textAlign: 'center',
                                fontFamily: 'Inria Sans',
                                fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                fontWeight: 400,
                                textTransform: 'uppercase',
                                minHeight: { xs: '48px', sm: '54px', md: '60px' }, // Ensures consistent height for text area
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {t(`WorkProcess.${process.text}`)}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>




    )

}